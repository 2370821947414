<template>
  <div class="d-flex-between mb-50">
    <template v-for="(filterType, ftIndex) of filterTypes">
      <b-button
        :key="ftIndex"
        :variant="sortBySelected === filterType.value ? 'outline-warning' : 'outline-secondary'"
        size="sm"
        :class="`d-flex-center flex-grow-1 fw-700 font-small-3
        ${['lg','xl'].includes(appBreakPoint) ? 'px-25 mr-25' : 'mx-25 py-50'}`"
        pill
        @click="handleChangeFilter(filterType.value)"
      >
        <!-- Rẻ nhất -->
        {{ $t(`flight.${filterType.name}`) }}
      </b-button>
    </template>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'

import store from '@/store'

export default {
  components: {
    BButton,
  },
  setup() {
    const sortBySelected = computed(() => store.getters['app-flight-v2/getSortItinerariesType'])

    const filterTypes = [
      {
        name: 'cheapestFlight',
        value: 'CHEAPEST_FARE',
      },
      {
        name: 'earliestFlight',
        value: 'EARLIEST_DEPARTURE',
      },
      {
        name: 'fastestFlight',
        value: 'SHORTEST_DURATION',
      },
      {
        name: 'airlineShort',
        value: 'AIRLINE',
      },
    ]

    function handleChangeFilter(value) {
      store.dispatch('app-flight-v2/setSortItinerariesType', value)
    }

    return {
      filterTypes,
      sortBySelected,
      handleChangeFilter,
    }
  },
}
</script>
<style lang="">

</style>
